import { defineMessages } from 'react-intl';

export default defineMessages({
  title1: {
    id: 'fight.message.title1',
    defaultMessage: 'Les formules standardisées 👎',
  },
  content1: {
    id: 'fight.message.content1',
    defaultMessage:
      'Parce que chaque élève se trouve dans une situation unique et mérite une formule personnalisée.\n👉 Chez Les Sherpas, vous choisissez votre professeur, votre rythme et votre budget !',
  },
  title2: {
    id: 'fight.message.title2',
    defaultMessage: 'Les prix trop élevés 👎',
  },
  content2: {
    id: 'fight.message.content2',
    defaultMessage:
      "Parce que le soutien scolaire doit être accessible à tous, nos tarifs débutent à 15€ de l'heure.",
  },
  title3: {
    id: 'fight.message.title3',
    defaultMessage: 'Les professeurs exploités 👎',
  },
  content3: {
    id: 'fight.message.content3',
    defaultMessage:
      "Si nous sommes 30% moins cher, c'est parce que nous laissons nos professeurs fixer leurs tarifs et que nous appliquons une juste commission.",
  },
  title4: {
    id: 'fight.message.title4',
    defaultMessage: "Le manque d'accompagnement 👎",
  },
  content4: {
    id: 'fight.message.content4',
    defaultMessage:
      "Parce que la scolarité et l'apprentissage sont des sujets importants, vous bénéficiez d'un suivi personnalisé à tout moment.",
  },
});
