import React, { ComponentProps, ElementType, FC, ReactElement } from 'react';
import { Typography } from '@mui/material';

import ContainerWithIrregularBackground from '@/components/LandingMozza/common/Fight/ContainerWithIrregularBackground/ContainerWithIrregularBackground';
import Icon from '@/designSystem/Icon/Icon';

import useStyles from './styles';

type BaseBenefitProps = {
  title: string | ReactElement;
  content: string | ReactElement;
};

type Props = BaseBenefitProps &
  (
    | {
        seoIcon: ComponentProps<typeof Icon>['icon'];
        landingIcon?: never;
      }
    | { landingIcon: ElementType; seoIcon?: never }
  );

const Benefit: FC<Props> = ({ title, content, seoIcon, landingIcon }) => {
  const { classes } = useStyles();

  // help React understand that if there is no `seoIcon` passed, `landingIcon` cannot be of type `never`
  const LandingIcon = landingIcon as ElementType | undefined;

  return (
    <div className={classes.container}>
      {seoIcon ? (
        <Icon icon={seoIcon} size="xlarge" color="attention" />
      ) : (
        <LandingIcon />
      )}
      <ContainerWithIrregularBackground>
        <Typography variant="title2Xs" component="h3" className={classes.title}>
          {title}
        </Typography>
      </ContainerWithIrregularBackground>
      <Typography component="p" className={classes.description}>
        {content}
      </Typography>
    </div>
  );
};

export default Benefit;
