import { ComponentProps, ElementType, FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import useStyles from 'src/components/LandingMozza/common/Fight/styles';

import BookOpen from '@/Atoms/Icons/BookOpenOutlinedIcon';
import EuroCurrencyIcon from '@/Atoms/Icons/EuroCurrencyIcon';
import HandShakeIcon from '@/Atoms/Icons/HandShakeIcon';
import SadIcon from '@/Atoms/Icons/SadIcon';
import Cotillon from '@/components/LandingMozza/common/Cotillon/Cotillon';
import Benefit from '@/components/LandingMozza/common/Fight/Benefit/Benefit';
import FightCotillonSvg from '@/components/LandingMozza/common/Fight/FightCotillonSvg';
import Header from '@/components/LandingMozza/common/Fight/Header/Header';
import PaddingContainer from '@/components/PaddingContainer/PaddingContainer';
import Icon from '@/designSystem/Icon/Icon';

import messages from './messages';

interface Props {
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  cards?: { title: string | ReactElement; content: string | ReactElement }[];
  isForSeo?: boolean;
}

const Fight: FC<Props> = ({ title, subtitle, cards, isForSeo = false }) => {
  const { classes, cx } = useStyles();

  const seoIcons: ComponentProps<typeof Icon>['icon'][] = [
    'chatAlt2Outlined',
    'sparklesOutlined',
    'checkCircleOutlined',
    'userGroupOutlined',
  ];

  const landingIcons: ElementType[] = [
    BookOpen,
    EuroCurrencyIcon,
    SadIcon,
    HandShakeIcon,
  ];

  return (
    <div className={classes.container}>
      <PaddingContainer>
        <Cotillon top={78} left={-46}>
          <FightCotillonSvg />
        </Cotillon>
        <Header title={title} subtitle={subtitle} />
      </PaddingContainer>
      <div className={classes.cardsContainer}>
        <div className={cx(classes.cards)}>
          {cards
            ? cards.map(({ title, content }, index) => (
                <Benefit
                  key={`benefit_${index}`}
                  title={title}
                  content={content}
                  {...(isForSeo
                    ? { seoIcon: seoIcons[index] }
                    : {
                        landingIcon: landingIcons[index],
                      })}
                />
              ))
            : new Array(4).fill(0).map((_, index) => (
                <Benefit
                  key={`benefit_${index}`}
                  title={
                    <FormattedMessage {...messages[`title${index + 1}`]} />
                  }
                  content={
                    <FormattedMessage {...messages[`content${index + 1}`]} />
                  }
                  {...(isForSeo
                    ? { seoIcon: seoIcons[index] }
                    : {
                        landingIcon: landingIcons[index],
                      })}
                />
              ))}
        </div>
      </div>
    </div>
  );
};
export default Fight;
